import { normalizePath } from '~/rpcMethods'

export default (_path: string, isInEditorMode: boolean) => {
  const $currentShop = useCurrentShop()
  const path = normalizePath(_path)
  const key = `${$currentShop.value.locale}${path}:${isInEditorMode ? 'preview' : 'published'}`

  const getCmsStory = useRpcCall('getCmsStory')
  const getCategoryPaths = useRpcCall('getCategoryPaths')
  const getPageTypes = useRpcCall('getPageTypes')

  const checkContentPages = async (
    normalizedPath: string,
    isInEditorMode: boolean,
  ) => {
    try {
      const data = await getCmsStory({
        slug: normalizedPath,
        baseFolder: getLocalizedBaseFolder(
          $currentShop.value,
          CmsBaseFolder.Content,
        ),
        isCmsPreview: isInEditorMode,
        ignoreNotFound: true,
      })
      if (
        data?.full_slug &&
        (data?.full_slug?.includes(path) ||
          (isInEditorMode &&
            data?.translated_slugs
              ?.map((slug: { path: string }) => normalizeFullSlug(slug.path))
              ?.includes(path)))
      ) {
        return PAGE_TYPE_CONTENT_PAGES
      }
    } catch {
      // nothing found
    }
  }

  const checkCategoryPaths = async (normalizedPath: string, locale: string) => {
    try {
      const data = await getCategoryPaths()
      const found = data.includes(normalizedPath)
      const contactLensPath = getContactLensesPath(locale)
      const lensesCarePath = getLensesCarePath(locale)
      if (found && normalizedPath.startsWith(lensesCarePath)) {
        return PAGE_TYPE_LENSES_CARE_PLP
      }

      if (found && normalizedPath.startsWith(contactLensPath)) {
        return PAGE_TYPE_LENSES_PLP
      }

      if (found) {
        return PAGE_TYPE_DEFAULT_PLP
      }
    } catch {
      // nothing found
    }
  }

  const checkPageTypes = async (
    normalizedPath: string,
    isInEditorMode: boolean,
  ) => {
    try {
      const data = await getPageTypes({ isCmsPreview: isInEditorMode })
      return data?.mapping?.[normalizedPath]
    } catch {
      // nothing found
    }
  }

  return useAsyncData(
    key,
    async () => {
      try {
        const locale = $currentShop.value.locale

        // first try cached pagetypes
        const pageType = await checkPageTypes(path, false)
        if (pageType) {
          return pageType
        }

        // categories have higher priority
        const plpType = await checkCategoryPaths(path, locale)
        if (plpType) {
          return plpType
        }

        // check content pages & respect editor mode
        const contentPageType = await checkContentPages(path, isInEditorMode)
        if (contentPageType) {
          return contentPageType
        }
      } catch {
        // nothing found
      }

      return PAGE_TYPE_INVALID
    },
    {
      dedupe: 'defer',
      server: true,
      immediate: true,
    },
  )
}
