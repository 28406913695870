import pc from 'picocolors'
import { Log, type LogEntry } from '@scayle/storefront-nuxt'
import { createConsola } from 'consola'
import { isPaginatedResponse, isProductsResponse } from './typeguards'
const isProd = process.env.APP_ENV === 'production'

// const consola = process.client
//   ? require('consola/dist/consola.browser.js')
//   : require('consola/dist/consola.js')

function prepareData(data: any) {
  if (!data) {
    return data
  }
  if (data.response) {
    const { response, message, name, code, request, config, ...other } = data
    return {
      message,
      name,
      code,
      status: response?.status,
      statusText: response?.statusText,
      ...other,
    }
  }
  return data
}

const generateLog = () => {
  const logger = createConsola({
    level: 3, // set to 3 to skip debug messages
  })

  const handler = (entry: LogEntry) => {
    if (isProd && entry.level === 'debug') {
      return
    } // ignore debug messages in production
    const data = prepareData(entry.data)
    const log = logger[entry.level] || logger.info
    const message = `[${entry.space}] ${entry.message}`
    const args = data ? [message, data] : [message]

    log.apply(log, args as any)
  }

  return new Log({
    space: 'fim', // camel-case
    handler,
  })
}
export const logger = generateLog()

export function withLogging<T extends (...args: any[]) => Promise<any>>(
  originalFunction: T,
  functionName: string = originalFunction.name,
): T {
  const wrapper = async (...args: Parameters<T>): Promise<ReturnType<T>> => {
    const logArgs = args.filter((arg) => !arg?.bapiClient)
    try {
      const result = await originalFunction(...args)

      const isEmpty =
        (isPaginatedResponse(result) && result.pagination.total === 0) ||
        (isProductsResponse(result) && result.entities.length === 0) ||
        !result

      if (isEmpty) {
        console.log(
          pc.magenta(
            `RPC DEBUG: Function ${functionName} resulted in empty response for:`,
          ),
          JSON.stringify(logArgs),
        )
      } else if (isProductsResponse(result)) {
        console.log(
          pc.magenta(
            `RPC DEBUG: Function ${functionName} has ${result.entities.length}/${result.pagination.total} items`,
          ),
          result.entities.length !==
            Math.min(result.pagination.perPage, result.pagination.total)
            ? JSON.stringify(logArgs)
            : '',
        )
      } else if (isPaginatedResponse(result)) {
        console.log(
          pc.magenta(
            `RPC DEBUG: Function ${functionName} has ${result.pagination.total} pagination items`,
          ),
        )
      }

      return result
    } catch (error) {
      console.error(
        pc.magenta(
          `RPC DEBUG: Function ${functionName} encountered an error: `,
        ),
        JSON.stringify(logArgs),
        error,
      )
      throw error
    }
  }

  // see https://2ality.com/2015/09/function-names-es6.html#changing-the-names-of-functions
  Object.defineProperty(wrapper, 'name', {
    value: functionName,
    configurable: true,
  })

  return wrapper as T
}
